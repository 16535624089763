<template>
	<section class="container has-margin-bottom-120 has-text-centered">
		<h2 class="title is-size-1 has-margin-top-20 has-margin-bottom-80 has-text-centered has-text-weight-normal">Subscribe to our habit stacking newsletter</h2>

		<a href="https://habitapp.substack.com" target="_blank" class="button has-background-black is-radiusless">
			<img src="/assets/substack-logo.png" height="20" width="18" class="has-margin-right-15">
			<div class="has-text-left has-text-weight-medium button-text__column">
				<small>Follow us on</small>
				<span class="">Substack</span>
			</div>
		</a>
	</section>
</template>

<script>
  export default {
    name: "FollowUs"
  }
</script>

<style scoped>

</style>
