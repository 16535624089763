<template>
	<div class="container">
		<h1 class="title is-size-1 has-padding-top-40-mobile has-padding-top-100-tablet has-text-weight-normal">HabitApp: Getting Started with Habit Stacking</h1>
		<h6 class="is-size-h6 has-text-weight-light has-padding-top-50 has-margin-bottom-80">You're on your way to
			developing lifelong,
			positive habits the easy way by downloading the HabitApp. This guide will step you through the HabitApp
			App process.</h6>
		<section class="has-margin-bottom-120 is-flex wrap-mobile justify-space-between">
			<div class="has-margin-right-0 has-margin-right-100-tablet">
				<div class="section-icon is-flex justify-center align-items-center">
					<img src="/assets/timer-icon.svg"/>
				</div>
				<h5 class="is-size-h5 has-text-weight-normal has-padding-top-25">Step 1: Think about the habits you
					already
					perform every day.</h5>
				<div class="has-text-weight-light has-margin-bottom-25 has-margin-top-5">We all have habits we do daily,
					what are yours? Our users frequently list the following habits:
				</div>
				<ul class="text-list list-double-column has-margin-bottom-35">
					<li>
						Brush teeth
					</li>
					<li>
						Wake up
					</li>
					<li>
						Reading Internet news
					</li>
					<li>
						Making coffee
					</li>
					<li>
						Exercise
					</li>
					<li>
						Meditate
					</li>
					<li>
						Tuck in kids
					</li>
					<li>
						Read book
					</li>
					<li>
						Measure blood pressure
					</li>
					<li>
						Measure weight
					</li>
					<li>
						Call mom
					</li>
				</ul>
				<div class="has-text-weight-light has-margin-bottom-15 has-margin-top-5">How much time do these habits
					take
					you to perform? Make a note of it and input it into the app.
				</div>
			</div>
			<phone video="/assets/Video1" class=" has-margin-left-auto-mobile has-margin-right-auto-mobile"/>
		</section>
		<section class="has-margin-bottom-120 is-flex wrap-mobile justify-space-between">
			<div class="has-margin-right-100-tablet">
				<div class="section-icon is-flex justify-center align-items-center">
					<img src="/assets/habits-icon.svg"/>
				</div>
				<h5 class="is-size-h5 has-text-weight-normal has-padding-top-25">Step 2: Think about a habit you can
					build on top of the habit you already perform every day.</h5>
				<div class="has-text-weight-light has-margin-bottom-15 has-margin-top-5">For example, if you want to
					make sure you exercise every day after you wake-up. You would create a habit stack of two habits
					called 'morning routine':
				</div>
				<ul class="text-list has-margin-bottom-35 has-margin-top-15">
					<li>
						Wake-up (5 minutes)
					</li>
					<li>
						Exercise (30 minutes)
					</li>
				</ul>
				<div class="has-text-weight-light has-margin-bottom-15 has-margin-top-5">Now, when you wake-up every day
					just run your morning routine habit stack. You'll be presented with a timer that shows you how much
					time you have to wake-up and after 5 minutes you'll be prompted to exercise for 30 minutes.
				</div>
			</div>
			<phone video="/assets/Video2" class="has-margin-left-auto-mobile has-margin-right-auto-mobile"/>
		</section>
		<section class="has-margin-bottom-120 is-flex wrap-mobile justify-space-between">
			<div class="has-margin-right-100-tablet">
				<div class="section-icon is-flex justify-center align-items-center">
					<img src="/assets/habits-icon.svg"/>
				</div>
				<h5 class="is-size-h5 has-text-weight-normal has-padding-top-25">Step 3: Repeat.</h5>
				<p class="has-text-weight-light has-margin-bottom-35 has-margin-top-5">Research suggests that it may
					take up to 21 days for a new habit to become automatic. However, now that you have a habit stack
					created, simply run the habit stacks you created daily to build new habits.
				</p>
				<p class="has-text-weight-light has-margin-bottom-35 has-margin-top-5">When you feel like you've
					developed a habit that sticks, add another positive habit to your habit stack!
				</p>
				<p class="has-text-weight-light has-margin-bottom-35 has-margin-top-5">What new habit will you develop
					next?
				</p>
			</div>
			<phone video="/assets/Video3" class="has-margin-left-auto-mobile has-margin-right-auto-mobile"/>
		</section>
	</div>
</template>

<script>
	import Phone from "../components/Phone"

	export default {
		name: "GettingStarted",
		components: {Phone},
		mounted() {
			document.dispatchEvent(new Event('render-event'))
		}
	}
</script>

<style scoped lang="scss">
	section {
		line-height: 1.78;
	}

	.section-icon {
		box-sizing: border-box;
		height: 48px;
		width: 48px;
		border-radius: 10px;
		background-color: #523F4B;
		border: 1px solid #973F43;

		img {
			height: 24px;
			width: 24px;
		}
	}
</style>
