<template>
	<section class="container has-margin-bottom-120 has-text-centered-mobile">
		<h2 class="title is-size-1 has-margin-top-20 has-margin-bottom-80 has-text-centered has-text-weight-normal">
			Download HabitApp!  The world's best habit app for habit stacking.</h2>

		<div class="columns is-flex wrap is-centered">
			<div class="column is-3 has-text-centered">
				<app-store-btn/>
			</div>
			<div class="column is-3 has-text-centered">
				<google-play-btn/>
			</div>
		</div>
	</section>
</template>

<script>
import AppStoreBtn from '../components/AppStoreBtn'
import GooglePlayBtn from '../components/GooglePlayBtn'

export default {
	name: "DownloadApp",
	components: {
		AppStoreBtn,
		GooglePlayBtn
	}
}
</script>

<style scoped>

</style>
