<template>
	<div id="app" data-server-rendered="true">

		<a-header></a-header>

		<router-view></router-view>

		<download-app class="has-padding-top-40"/>

		<follow-us class="has-padding-bottom-80-tablet has-padding-bottom-40-mobile"/>

		<footer class="container has-margin-bottom-40 has-text-centered-mobile">
			<div class="is-flex wrap-mobile justify-space-between has-margin-top-80">
				<div class="is-full-width-mobile is-hidden-mobile">
					<p class="has-text-light">© 2020 HabitApp. All Rights Reserved.</p>
				</div>
				<div class="is-full-width-mobile">
					<div class="is-flex justify-flex-end-tablet justify-center-mobile">
						<div class="has-margin-left-20-tablet has-margin-right-20-tablet has-margin-right-80-mobile">
							<router-link to="/privacy" class="has-text-weight-normal has-text-white">Privacy Policy</router-link>
						</div>
						<div class="has-margin-left-20-tablet has-margin-right-20-tablet">
							<router-link to="/terms" class="has-text-weight-normal has-text-white">Terms of Service</router-link>
						</div>
					</div>
				</div>
				<div class="is-full-width-mobile has-margin-top-40-mobile is-hidden-tablet is-block-mobile">
					<p class="has-text-light">© {{ new Date().getFullYear() }} HabitApp. All Rights Reserved.</p>
				</div>
			</div>
		</footer>

	</div>
</template>

<script>
import AHeader from './components/AHeader'
import DownloadApp from "./components/DownloadApp"
import FollowUs from "./components/FollowUs"

export default {
	components: {
		FollowUs,
		DownloadApp,
		AHeader,
	}
}
</script>

<style lang="scss">

@import './scss/main.scss';

.container {
	max-width: 936px !important;
}

.container__overflowed {
	overflow: hidden;
}

.button-text__column {
	display: flex;
	flex-direction: column;
}

.button {
	&.is-small {
		height: 2.5em;
	}

	&.is-transparent {
		background-color: transparent;
	}

	&.has-background-black:hover {
		background-color: #0A0A0A !important;
	}

	&.has-background-info:hover {
		background-color: #3978E1 !important;
	}

	.icon {
		&.is-xsmall {
			width: $icon-dimensions-xsmall;
			height: $icon-dimensions-xsmall;
		}

		&.is-medium {
			width: $icon-dimensions-medium;
			height: $icon-dimensions-medium;
		}
	}
}

a.has-text-light:hover,
a.has-text-light:focus {
	color: #D25A5F !important;
}

.has-margin-left-0 {
	margin-left: 0 !important;
}

.has-margin-right-0 {
	margin-right: 0 !important;
}

.has-text-bigger {
	font-size: 1.1111em;
	line-height: 1.8em;
}

.is-glow {
	background-color: $primary;
	box-shadow: 0 0 20px 0 rgba(210,90,95,0.4);
}

.is-ghost {
	background-color: #523F4B;
	border: 1px solid #973F43;
}

.circle {
	height: 12em;
	width: 12em;
	background-color: $primary;
	// box-shadow: 0 0 70px 0 rgba(210,90,95,0.5);
	border-radius: 50%;
	position: absolute;

	&.is-glow {
		box-shadow: 0 0 70px 0 rgba(210,90,95,0.4);
	}
}

.circle__back {
	z-index: -1;
	right: -80px;
	top: 64px;
}

.circle__front {
	height: 7.5555em;
	width: 7.5555em;
	bottom: 80px;
	left: -18px;
	z-index: 1;
	box-shadow: none;
}

.circle__small {
	width: 1.778em;
	height: 1.778em;
	transition: 0.15s ease-in all;
}

.icon {
	border-radius: 12px;
}

@media screen and (max-width: 960px) {
	.container {
		padding-left: 1.111em;
		padding-right: 1.111em;
	}

	footer .columns {
		flex-direction: column-reverse;
	}
}

</style>
