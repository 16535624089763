<template>
<div class="container has-margin-bottom-100">
	<h1 class="title is-size-1 has-padding-top-40-mobile has-padding-top-100-tablet has-text-weight-normal">HabitApp: Privacy Policy</h1>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-50">
			Post PC Labs, LLC PRIVACY POLICY
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			Post PC Labs, LLC (the “Company”) is committed to
			maintaining robust privacy protections for its users.
			Our
			Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard
			the
			information you provide to us and to assist you in making informed decisions when using our Service.
		</p>
		<p class="has-text-weight-light has-padding-top-40">
			For purposes of this Agreement, “Site” refers to the
			Company’s website, which can be accessed at
			<a href="https://habit.app">https://habit.app</a>
		</p>
		<p class="has-text-weight-light has-padding-top-40">
			“Service” refers to the Company’s services accessed via
			the mobile application called "HabitApp", in which users can use the tool to perform and track activities which the application refers to as "habits."
		</p>
		<p class="has-text-weight-light has-padding-top-40">
			The terms “we,” “us,” and “our” refer to the Company.
		</p>
		<p class="has-text-weight-light has-padding-top-40">
			“You” refers to you, as a user of our Site or our Service.
		</p>
		<p class="has-text-weight-light has-padding-top-40">
			By accessing our Site or our Service, you accept our
			Privacy Policy and Terms of Use (found here:
			<a href="https://habit.app/terms">https://habit.app/terms</a>), and
			you consent to our collection, storage, use and disclosure
			of your Personal Information as described in this Privacy Policy.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">I. INFORMATION WE COLLECT</h5>
		<p class="has-text-weight-light has-padding-top-40">
			We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes
			information that cannot be used to personally identify you, such as anonymous usage data, general
			demographic information we may collect, referring/exit pages and URLs, platform types, preferences you
			submit and preferences that are generated based on the data you submit and number of clicks. Personal
			Information includes your email, which you submit to us through the registration process at the Site.
		</p>
		<p class="has-text-weight-normal has-padding-top-40">
			Information collected via Technology
		</p>
		<p class="has-text-weight-light">
			To activate the Service you do not need to submit any
			Personal Information other than your email address. To use the Service thereafter, you will have the
			option to submit further Personal Information, which may include your first and/or last name. However,
			in an effort to improve the quality of the Service, we track information provided to us by your browser
			or by our software application when you view or use the Service, such as the website you came from
			(known as the “referring URL”), the type of browser you use, the device from which you connected to the
			Service, the time and date of access, and other information that does not personally identify you. We
			track this information using cookies, or small text files which include an anonymous unique identifier.
			Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive.
			Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and
			keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate
			basis. For example, the Company may use cookies to collect the following information:</p>
		<ul class="text-list">
			<li>
				Browser type & version
			</li>
			<li>
				Device type
			</li>
			<li>
				Preferred login method (Google single sign-on vs Apple single sign-on vs Facebook single sign-on)
			</li>
			<li>
				Referring site(s) & keywords
			</li>
		</ul>
		<p class="has-text-weight-light has-padding-top-40">
			The Company may use both persistent and session cookies;
			persistent cookies remain on your computer after you close your session and until you delete them, while
			session cookies expire when you close your browser.
		</p>
		<p class="has-text-weight-normal has-padding-top-40">
			Information you provide us by registering for an account
		</p>
		<p class="has-text-weight-light">
			In addition to the information provided automatically by
			your browser when you visit the Site, to become a subscriber to the Service you will need to create a
			personal profile. You can create a profile by registering with the Service and either 1) entering your
			email address and creating an associated password, or 2) verifying your identity via Google or Microsoft
			single sign-on services. By registering, you are authorizing us to collect, store and use your email
			address in accordance with this Privacy Policy.
		</p>
		<p class="has-text-weight-normal has-padding-top-40">
			Children’s Privacy
		</p>
		<p class="has-text-weight-light">
			The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly
			collect or solicit information from anyone under the age of 13, or allow anyone under the age of 13 to
			sign up for the Service. In the event that we learn that we have gathered personal information from
			anyone under the age of 13 without the consent of a parent or guardian, we will delete that information
			as soon as possible. If you believe we have collected such information, please contact us at habit-support@postpclabs.com.
		</p>
		<p class="has-text-weight-normal has-padding-top-40">
			Your Choices and Rights - Deleting Your Information
		</p>
		<p class="has-text-weight-light">
			You may at any time request the deletion of your personal information. Note that if your information is deleted, then your account 
			will be deactivated. If your account is deactivated or you ask to close your account, you will no longer be able to use the Service. 
			If you would like to delete your account in our system, you can do so by contacting habit-support@postpclabs.com or by deleting the account
			through the app. All information you have added to the Service will no longer be recoverable. 
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			II. HOW WE USE AND SHARE INFORMATION
		</h5>
		<p class="has-text-weight-normal has-padding-top-40">
			Personal Information
		</p>
		<p class="has-text-weight-light">
			Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for
			marketing purposes your Personal Information with third parties without your consent. We do share
			Personal Information with vendors who are performing services for the Company, such as the servers for
			our email communications who are provided access to user’s email address for purposes of sending emails
			from us. Those vendors use your Personal Information only at our direction and in accordance with our
			Privacy Policy. In general, the Personal Information you provide to us is used to help us communicate
			with you. For example, we use Personal Information to contact users in response to questions, solicit
			feedback from users, provide technical support, and inform users about promotional offers. We may share
			Personal Information with outside parties if we have a good-faith belief that access, use, preservation
			or disclosure of the information is reasonably necessary to meet any applicable legal process or
			enforceable governmental request; to enforce applicable Terms of Service, including investigation of
			potential violations; address fraud, security or technical concerns; or to protect against harm to the
			rights, property, or safety of our users or the public as required or permitted by law.
		</p>
		<p class="has-text-weight-normal has-padding-top-40">
			Non-Personal Information
		</p>
		<p class="has-text-weight-light">
			In general, we use Non-Personal Information to help us improve the Service and customize the user
			experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns
			on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal
			Information and we reserve the right to use and disclose such Non-Personal Information to our partners,
			advertisers and other third parties at our discretion. In the event we undergo a business transaction
			such as a merger, acquisition by another company, or sale of all or a portion of our assets, your
			Personal Information may be among the assets transferred. You acknowledge and consent that such
			transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may
			continue to process your Personal Information as set forth in this Privacy Policy. If our information
			practices change at any time in the future, we will post the policy changes to the Site so that you may
			opt out of the new information practices. We suggest that you check the Site periodically if you are
			concerned about how your information is used.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			III. HOW WE PROTECT INFORMATION
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			We implement security measures designed to protect your information from unauthorized access. Your
			account is protected by your account password and/or Google/Apple/Facebook single sign-on services, and we
			urge you to take steps to keep your personal information safe by not disclosing your password and by
			logging out of your account after each use. We further protect your information from potential security
			breaches by implementing certain technological security measures including encryption, firewalls and
			secure socket layer technology. However, these measures do not guarantee that your information will not
			be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By
			using our Service, you acknowledge that you understand and agree to assume these risks.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			You have the right at any time to prevent us from contacting you for marketing purposes. When we send a
			promotional communication to a user, the user can opt out of further promotional communications by
			following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that
			you do not wish to receive marketing communications from us in the User Profile section of the Site.
			Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or
			opting out in the User Profile section of the Site, we may continue to send you administrative emails
			including, for example, periodic updates to our Privacy Policy.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			V. LINKS TO OTHER WEBSITES
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			As part of the Service, we may provide links to or compatibility with other websites or applications.
			However, we are not responsible for the privacy practices employed by those websites or the information
			or content they contain. This Privacy Policy applies solely to information collected by us through the
			Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website
			accessed by selecting a link on our Site or via our Service. To the extent that you access or use the
			Service through or on another website or application, then the privacy policy of that other website or
			application will apply to your access or use of that site or application. We encourage our users to read
			the privacy statements of other websites before proceeding to use them.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			VI. CHANGES TO OUR PRIVACY POLICY
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			The Company reserves the right to change this policy and our Terms of Service at any time. We will
			notify you of significant changes to our Privacy Policy by sending a notice to the primary email address
			specified in your account or by placing a prominent notice on our site. Significant changes will go into
			effect 30 days following such notification. Non-material changes or clarifications will take effect
			immediately. You should periodically check the Site and this privacy page for updates.
		</p>
	</article>
	<article class="has-padding-bottom-40">
		<h5 class="is-size-5 has-text-weight-normal has-padding-top-40">
			VII. CONTACT US
		</h5>
		<p class="has-text-weight-light has-padding-top-40">
			If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us
			by sending an email to habit-support@postpclabs.com
		</p>
		<p class="has-text-weight-light has-padding-top-40 text-gray">
			Last Updated: This Privacy Policy was last updated on Jul 31, 2020.
		</p>
	</article>
</div>
</template>

<script>
export default {
	name: "PrivacyPolicy",
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}
</script>

<style scoped>
article {
	line-height: 1.78;
}

a {
	color: #D25A5F;
}
</style>
