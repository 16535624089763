<template>
	<div class="container">
		<h1 class="title is-size-1 has-padding-top-40-mobile has-padding-top-100-tablet has-text-weight-normal">HabitApp: Habit Stacking Newsletter</h1>
		<div v-html="html" class="news container has-margin-bottom-100 has-margin-top-100"></div>
	</div>
</template>

<script>
	const RSS_URL = 'https://meeting.town/substack-proxy/habbitapp/feed'
	export default {
		name: "Newsletter",
		data: () => ({
			html: ''
		}),
		mounted () {
			function parseCDATA (htmlString) {
				return htmlString.innerHTML.replace('<![CDATA[','').replace(']]>', '')
			}
			fetch(RSS_URL)
				.then(response => response.text())
				.then(str => new window.DOMParser().parseFromString(str, "text/xml"))
				.then(data => {
					const items = data.querySelectorAll("item");
					items.forEach(el => {
						this.html += `
		  <h2 class="news-title is-size-h2 has-text-white has-margin-bottom-25">
            <a href="${el.querySelector("link").innerHTML}" target="_blank" rel="noopener" class="has-text-white">
              ${parseCDATA(el.querySelector("title"))}
            </a>
          </h2>
          <img src="${el.querySelector("enclosure").getAttribute('url')}" alt="" class="news-img has-margin-bottom-20"/>
          <p class="news-description has-text-weight-lig has-margin-bottom-25">
		  	${parseCDATA(el.querySelector("description"))}
		  </p>
          <a class="news-link has-text-primary has-margin-bottom-70" href="${el.querySelector("link").innerHTML}">Read More</a>
      `;
					});
					document.dispatchEvent(new Event('render-event'))
				});
		}
	}
</script>

<style scoped lang="scss">
.news {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-flow: row dense;
	grid-column-gap: 56px;
	font-family: "Barlow Condensed";
	font-weight: 300;

	@media (min-width: 769px) {
		grid-template-columns: 1fr 1fr;
	}
}
</style>
