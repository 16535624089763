<template>
	<div>
		<section class="container">
			<div class="columns is-centered has-padding-bottom-90">
				<div class="column is-12 has-text-centered-mobile has-text-centered">
					<h1 class="title is-size-1 has-padding-top-40-mobile has-padding-top-100-tablet has-text-weight-normal">HabitApp is the habit app you need to implement habit stacking</h1>
					<p class="has-text-light is-size-1_2em has-text-weight-normal">HabitApp is the first habit app to implement "habit stacking" based on the latest research discussed in James Clear's book, "Atomic Habits". Habit Stacking helps people develop positive habits by building on what you already do and time boxing your activities so you can develop consistency.</p>
					<div class="has-padding-top-40-mobile has-padding-top-80-tablet">
						<div class="columns is-flex wrap is-centered">
							<div class="column is-3 has-text-centered">
								<app-store-btn/>
							</div>
							<div class="column is-3 has-text-centered">
								<google-play-btn/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

<!--			<div class="action-link is-hidden-touch is-flex-desktop has-margin-left-30 has-cursor-pointer" @click="showVideo = true">-->
<!--				<img src="/assets/Play-Icon.png" class="has-margin-right-5"/>-->
<!--				<span>See how it works</span>-->
<!--			</div>-->
		<section class="container has-padding-bottom-120-tablet has-margin-bottom-120-tablet has-padding-bottom-40-mobile has-margin-bottom-40-mobile has-text-centered-mobile">
			<div class="video-container has-margin-left-auto has-margin-right-auto">
				<iframe width="100%"
						src="https://www.youtube.com/embed/VTVWpNcBbZk">
				</iframe>
			</div>
		</section>

		<section class="container has-text-centered-mobile">
			<div class="icon is-glow is-large">
				<img src="/assets/settings.png" height="28" width="28">
			</div>
			<h2 class="title is-size-1 has-margin-top-20 has-text-weight-normal">How HabitApp works</h2>
			<p class="has-text-light is-size-1_2em has-text-weight-normal">HabitApp is a habit app that uses a three step approach to help you build
				habits using habit stacking.</p>

			<div class="columns has-margin-top-60 has-text-left-mobile">
				<div class="column is-hidden-mobile">
					<figure class="has-margin-top-10 is-relative">
						<img
							style="max-width: 300px;"
							:srcset="selected.srcSet"
							:src="`/assets/${selected.value}`"
							alt="Screenshot">
					</figure>
				</div>
				<div class="column">
					<select-group
						:items="list"
						:value="selected"
						@input="stopInterval"
					>
					</select-group>
				</div>
			</div>
		</section>

		<section
			class="container has-padding-top-120-tablet has-margin-top-120-tablet has-padding-bottom-120-tablet has-margin-bottom-80-tablet has-padding-top-40-mobile has-margin-top-40-mobile has-padding-bottom-40-mobile has-margin-bottom-40-mobile has-text-centered-mobile">
			<div class="icon is-glow is-large">
				<img src="/assets/benefit-icon.png" height="28" width="28">
			</div>
			<h2 class="title is-size-1 has-margin-top-20 has-margin-bottom-5 has-text-weight-normal">Benefits of using HabitApp</h2>
			<p class="has-text-light has-text-bigger">Start building new habits with our habit stacking technology today using our habit app!</p>

			<div class="columns has-margin-top-60">
				<div class="column">
					<div class="icon is-ghost is-medium has-margin-bottom-15">
						<img src="/assets/Flag-Icon.svg" height="24" width="24">
					</div>
					<p>Develop new habits and create habit stacks without the struggle.</p>
				</div>
				<div class="column">
					<div class="icon is-ghost is-medium has-margin-bottom-15">
						<img src="/assets/Focus-Icon.svg" height="24" width="24">
					</div>
					<p>Stay focused when you're performing your habit stack.</p>
				</div>
				<div class="column">
					<div class="icon is-ghost is-medium has-margin-bottom-15">
						<img src="/assets/Track-Icon.svg" height="24" width="24">
					</div>
					<p>Track your habits and habit stacks as you improve.</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import AppStoreBtn from '../components/AppStoreBtn'
	import GooglePlayBtn from '../components/GooglePlayBtn'
	import SelectGroup from '../components/SelectGroup'

	export default {
		name: "Home",
		components: {
			SelectGroup,
			AppStoreBtn,
			GooglePlayBtn
		},
		data: () => ({
			interval: null,
			list: [
				{
					text: 'HabitApp helps you create a new habit by building on exisiting habits. This is habit stacking.',
					icon: 'habits-icon.png',
					value: 'phone1-1x.png',
					srcSet: '/assets/phone1-1x.png, /assets/phone1-2x.png 2x'
				},
				{
					text: 'HabitApp\'s timer helps you stay on track as you perform your habit stack.',
					icon: 'timer-icon.png',
					value: 'phone2-1x.png',
					srcSet: '/assets/phone2-1x.png, /assets/phone2-2x.png 2x'
				},
				{
					text: 'HabitApp\'s tracker helpers you monitor your habits and habit stacks as you progress.',
					icon: 'progress-icon.png',
					value: 'phone3-1x.png',
					srcSet: '/assets/phone3-1x.png, /assets/phone3-2x.png 2x'
				},
			],
			selected: {
				text: 'HabitApp helps you create a new habit by building on exisiting habits. This is habit stacking.',
				icon: 'habits-icon.png',
				value: 'Phone1.png',
			},
			selectedIndex: 0
		}),
		methods: {
			stopInterval (newValue) {
				clearInterval(this.interval)
				this.selected = { ...newValue }
			}
		},
		mounted() {
			this.interval = setInterval(() => {
				this.selectedIndex = (++this.selectedIndex) % this.list.length
				this.selected = this.list[this.selectedIndex]
			}, 2000);
			document.dispatchEvent(new Event('render-event'))
		}
	}
</script>

<style scoped lang="scss">
	.action-link {
		color: #D25A5F;
		font-family: "Barlow Condensed";
		font-size: 20px;
		font-weight: 300;
		line-height: 24px;


		img {
			height: 24px;
			width: 24px;
		}
	}

	.pop-up {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		background: rgba(0,0,0,.8);

		.close {
			position: absolute;
			width: 30px;
			height: 30px;
			cursor: pointer;
			top: 25px;
			right: 40px;

			&:after, &:before {
				width: 30px;
				height: 2px;
				border-radius: 1px;
				background: white;
				position: absolute;
				top: 50%;
				right: 0px;
				content: '';
				cursor: pointer;
			}

			&:after {
				transform: rotate(45deg);
			}

			&:before {
				transform: rotate(-45deg);
			}
		}
	}

	.video-container {
		position: relative;
		padding-bottom: 56.25%; /* for youtube video 16:9 */
		height: 0;
		width: 100%;
		@media screen and (min-width: 800px) {
			width: 800px;
			padding-bottom: 450px; /* for youtube video 16:9 */
		}
	}

	.video-container iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;  /* for youtube video */
	}
</style>
