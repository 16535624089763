import Vue from 'vue'
import Router from 'vue-router'
import FAQ from '../pages/FAQ.vue'
import Home from '../pages/Home.vue'
import Newsletter from '../pages/Newsletter.vue'
import PrivacyPolicy from '../pages/PrivacyPolicy.vue'
import Terms from '../pages/Terms.vue'
import GettingStarted from "../pages/GettingStarted"

Vue.use(Router)

const router = new Router({
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
			meta: {
				title: 'HabitApp | Home Page',
				metaTags: [
					{
						name: 'description',
						content: 'Develop Positive Habits! HabitApp implements "habit stacking" based on the latest research from James Clear\'s book, "Atomic Habits".'
					},
					{
						property: 'og:description',
						content: 'Develop Positive Habits! HabitApp implements "habit stacking" based on the latest research from James Clear\'s book, "Atomic Habits".'
					}
				]
			}
		},
		{
			path: '/faq',
			name: 'FAQ',
			component: FAQ,
			meta: {
				title: 'HabitApp | FAQ',
				metaTags: [
					{
						name: 'description',
						content: 'Have questions about the HabitApp? Check out out FAQ page! Your question isn\'t listed? Reach out to us!'
					},
					{
						property: 'og:description',
						content: 'Have questions about the HabitApp? Check out out FAQ page! Your question isn\'t listed? Reach out to us!'
					}
				]
			}
		},
		{
			path: '/start',
			name: 'Getting Started',
			component: GettingStarted,
			meta: {
				title: 'HabitApp | Get Started Habit Stacking',
				metaTags: [
					{
						name: 'description',
						content: 'You\'re on your way to developing positive habits via habit stacking by downloading the HabitApp! This guide will step you through the HabitApp process.'
					},
					{
						property: 'og:description',
						content: 'You\'re on your way to developing positive habits via habit stacking by downloading the HabitApp! This guide will step you through the HabitApp process.'
					}
				]
			}
		},
		{
			path: '/newsletter',
			name: 'Newsletter',
			component: Newsletter,
			meta: {
				title: 'HabitApp | Newsletter Habit Stacking',
				metaTags: [
					{
						name: 'description',
						content: 'Learn how to do habit stacking easily with the Habit App by subscribing to our newsletter!'
					},
					{
						property: 'og:description',
						content: 'Learn how to do habit stacking easily with the Habit App by subscribing to our newsletter!'
					}
				]
			}


		},
		{
			path: '/privacy',
			name: 'PrivacyPolicy',
			component: PrivacyPolicy,
			meta: {
				title: 'HabitApp | Privacy Policy',
				metaTags: [
					{
						name: 'description',
						content: 'Our Privacy Policy is designed to help you understand how we collect, use and safeguard the information you provide to us.'
					},
					{
						property: 'og:description',
						content: 'Our Privacy Policy is designed to help you understand how we collect, use and safeguard the information you provide to us.'
					}
				]
			}
		},
		{
			path: '/terms',
			name: 'Terms',
			component: Terms,
			meta: {
				title: 'HabitApp | Terms of Service',
				metaTags: [
					{
						name: 'description',
						content: 'The HabitApp Terms of Use constitute a legally binding agreement made between you and Post PC Labs LLC concerning your access and use of the HabitApp website.'
					},
					{
						property: 'og:description',
						content: 'The HabitApp Terms of Use constitute a legally binding agreement made between you and Post PC Labs LLC concerning your access and use of the HabitApp website.'
					}
				]
			}
		}
	],
	mode: 'history',
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
