<template>
	<div class="container">

		<h1 class="title is-size-1 has-padding-top-40-mobile has-padding-top-100-tablet has-text-weight-normal">HabitApp: FAQs</h1>
		<BulmaAccordion
			dropdown
			:initial-open-items="[1]" 
			:icon="'custom'"
			:caretAnimation="{
					duration: '1s',
					timerFunc: 'ease-in-out'
			}"
			:slide="{
					duration: '.9s',
					timerFunc: 'ease'
			}"
	>
			<!-- The wrapper component for all the items -->
			<BulmaAccordionItem>
					<h5 slot="title" class="is-size-5 has-text-weight-normal">What is the difference between the standard version of HabitApp and HabitApp Pro?</h5>
					<div slot="icon-open">&#9650;</div>
					<div slot="icon-closed">&#9660;</div>
					<div slot="content">
					<div class="has-text-weight-normal">When you subscribe to HabitApp Pro you get:</div>
						<ul class="text-list">
							<li>
								Unlimited habit stacks (the standard version is limited to one stack)
							</li>
							<li>
								Unlimited habits (the standard version is limited to 7 habits)
							</li>
							<li>
								All other premium feature updates in future versions of the application
							</li>
						</ul>
					</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
					<h5 slot="title" class="is-size-5 has-text-weight-normal">How is a habit determined to be
						complete?</h5>
					<div slot="icon-open">&#9650;</div>
					<div slot="icon-closed">&#9660;</div>
					<div slot="content">
						<div class="has-text-weight-normal">A habit is complete if you execute a habit stack
							and:</div>
							<ul class="text-list">
								<li>
									The timer runs to zero on that habit and advances to the next habit
								</li>
								<li>
									The timer receives a swipe left, advancing to the next habit on the habit stack
								</li>
								<li>
									The timer receives a tap on the complete and advance button (bottom right of the timer)
								</li>
							</ul>
					</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
					<h5 slot="title" class="is-size-5 has-text-weight-normal">What if I have habit stacks that are
						similar,
						e.g. I have multiple morning routines depending on if it is the weekend or weekday?</h5>
					<div slot="icon-open">&#9650;</div>
					<div slot="icon-closed">&#9660;</div>
					<div slot="content">
						Supporting similar routines in the HabitApp is easy! Just create a new
							habit stack (e.g. morning
							routine 1, morning routine 2) and select and re-order your habits according to your preference.Supporting similar routines in the HabitApp is easy! Just create a new
								habit stack (e.g. morning
								routine 1, morning routine 2) and select and re-order your habits according to your preference.
					</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
					<h5 slot="title" class="is-size-5 has-text-weight-normal">If I am running out of time while performing
						a habit, how do I add time?</h5>
					<div slot="icon-open">&#9650;</div>
					<div slot="icon-closed">&#9660;</div>
					<div slot="content">
						You can add time while the timer is running by tapping on the add time button (bottom left corner).
					</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
					<h5 slot="title" class="is-size-5 has-text-weight-normal">Is there a way to jump straight into a
						habit stack when I launch the app?</h5>
					<div slot="icon-open">&#9650;</div>
					<div slot="icon-closed">&#9660;</div>
					<div slot="content">
						For both iOS and Android, we support the ability to launch directly into a
							habit stack on your phone's launcher. Simply tap and hold and you will be able to select the habit stack
							you want to launch.
					</div>
			</BulmaAccordionItem>
	</BulmaAccordion>

		<section class="container has-padding-bottom-140 has-text-centered has-margin-top-120">
			<h2 class="title is-size-1 has-margin-top-20 has-margin-bottom-80 has-text-centered has-text-weight-normal">Need More Help?</h2>

			<div class="button has-background-black is-radiusless">
				<img src="/assets/email-icon.png" height="20" width="18" class="has-margin-right-15">
				<div class="has-text-left has-text-weight-medium button-text__column">
					<small>Contact us at</small>
					<span>habit-support@postpclabs.com</span>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { BulmaAccordion, BulmaAccordionItem } from 'vue-bulma-accordion'

	export default {
		name: "FAQ",
		mounted() {
			document.dispatchEvent(new Event('render-event'))
		},
		components: {
        BulmaAccordion,
        BulmaAccordionItem
    }
	}
</script>

<style scoped>
	section {
		line-height: 1.78;
	}
	.icon {
		font-size: 1.0em
	}
</style>
