<template>
	<div class="has-margin-bottom-30-desktop">
		<label class="button select-item has-padding-20 has-text-left is-full-width justify-start has-text-weight-light is-size-1_2em"
			:class="{
				'is-dark': selected,
				'selected': selected,
				'is-transparent': !selected,
				'has-text-light': !selected,
			}">

			<slot></slot>

			<input
				v-model="computedValue"
				type="radio"
				:name="name"
				:value="nativeValue"/>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number, Boolean, Function, Object, Array, Symbol],
		nativeValue: [String, Number, Boolean, Function, Object, Array, Symbol],
		name: String,
	},
	data() {
		return {
			newValue: this.value
		};
	},
	computed: {
		computedValue: {
			get() {
				return this.newValue;
			},
			set(value) {
				this.newValue = value;
				this.$emit("input", value);
			}
		},
		selected() {
			return this.newValue.value === this.nativeValue.value;
		},
	},
	watch: {
		/**
		 * When v-model change, set internal value.
		 */
		value(value) {
			this.newValue = value;
		}
	}
};
</script>

<style scoped>

.select-item {
	cursor: pointer;
	white-space: normal;
	height: auto !important;
	letter-spacing: normal !important;
	color: #918CA0;
	transition: all 0.15s ease-in;
}

.select-item:hover {
	background: #2D3340;
}

.select-item [type="radio"] {
	display: none;
}

.select-item .icon {
	opacity: 0.75;
}

.select-item.selected .icon {
	opacity: 1;
}

</style>
