<template>
	<header :class="{ 'header-onscroll' : showScrolled }">
		<nav
			class="navbar is-flex align-stretch"
			:class="{ 'navbar--sticky is-fixed-top' : showScrolled, 'has-page-height': openMenu }"
			role="navigation"
			aria-label="main navigation">
			<div class="container is-block-touch is-flex-desktop is-clipped">
				<div class="is-flex justify-space-between">
					<div class="navbar-brand is-flex justify-space-between align-items-center">
						<a class="navbar-item" href="/" v-if="!openMenu">
							<img
								src="/assets/habit-logo.png"
								alt="HabitApp logo"
								width="75.43"
								height="24"/>
						</a>
					</div>

					<a
						role="button"
						class="navbar-burger burger has-text-white" aria-label="menu" aria-expanded="false"
						:class="{'is-active': openMenu}"
						@click="openMenu = !openMenu"
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>
				<div
					class="navbar-list navbar-menu is-active has-margin-left-auto align-items-center has-text-centered">
					<router-link
						v-for="link in links"
						:key="link.to"
						@click.native="openMenu = false"
						class="navbar-item"
						:class="{'is-size-6':!openMenu}"
						:to="link.to"
					>
						{{ link.title }}
					</router-link>
					<a
						href="mailto:habit-support@postpclabs.com"
						@click="openMenu = false"
						class="navbar-item"
						:class="{'is-size-6' : !openMenu}"
					>
						Email Us
					</a>
				</div>

			</div>
		</nav>
	</header>
</template>

<script>
	export default {
		data: () => ({
			showScrolled: false,
			openMenu: false,
			links: [
				{
					to: '/',
					title: 'Home Page'
				},
				{
					to: '/start',
					title: 'Getting Started'
				},
				{
					to: '/faq',
					title: 'FAQ'
				}
			]
		}),
		methods: {
			onScroll() {
				const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
				this.showScrolled = currentScrollPosition > 24
			},
		},
		mounted() {
			window.addEventListener('scroll', this.onScroll)
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.onScroll)
		}
	}
</script>

<style lang="scss">
	.header-onscroll {
		padding-top: 96px;
	}

	.navbar {
		height: 72px;
		margin-top: 24px;

		a.navbar-item.router-link-exact-active {
			color: #D25A5F;
			position: relative;
			border-bottom: 1px solid rgba(210, 90, 95, 0.5);
		}

		.download-buttons {
			transform: translateX(344px);
			width: 0;
			transition: 0.2s all ease-in-out;
		}
	}

	.navbar--sticky {
		margin-top: 0;
		border-bottom: 1px solid #3C404F;

		.download-buttons {
			transform: translateX(0);
			width: auto;
		}
	}

	.navbar-list {
		a.navbar-item, a.navbar-item:focus {
			display: inline;
			height: 24px;
			background: none;
			color: #B1ABC3;
			font-family: "Barlow Condensed";
			font-size: 16px;
			font-weight: 300;
			line-height: 24px;
			margin: 0 20px;
			padding: 0;

			&.navbar-item:hover, &.router-link-exact-active {
				color: #D25A5F;
				background: transparent;
			}
		}
	}

	.buttons {
		.button:first-child {
			margin-right: 24px !important;
		}
	}

	@media screen and (min-width: 1024px) {
		.navbar > .container .navbar-menu {
			margin-right: 0 !important;
		}
	}
</style>
