<template>
	<div class="phone">
		<div class="phone-display">
			<video controls>
				<source :src="video + '.webm'"  type="video/webm">
				<source :src="video + '.mp4'"  type="video/mp4">
				<p>This browser does not support the video element.</p>
			</video>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Phone",
		props: {
			video: {
				required: true,
				type: String
			}
		}
	}
</script>

<style scoped lang="scss">
	.phone {
		box-sizing: border-box;
		height: 514px;
		width: 296px;
		border-radius: 35px;
		background-color: #1A181E;
		border: 2px solid #464057;
		padding: 6px;

		.phone-display {
			height: 498px;
			width: 280px;
			border-radius: 30px;
			background-color: #2C313C;
			overflow: hidden;
		}
	}
</style>
