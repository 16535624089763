<template>
	<div>
		<select-item
			v-for="(item, key) in items"
			:key="key"
			v-model="selected"
			:nativeValue="item"
		>

			<div class="is-flex align-items-center is-marginless">
				<span class="has-padding-10 has-padding-right-20">
					<span class="icon is-ghost is-medium is-marginless">
						<img :src="`/assets/${item[itemIcon]}`" height="24" width="24">
					</span>
				</span>
				<span class="">
					{{ item[itemKey] }}
				</span>
			</div>

		</select-item>
	</div>
</template>

<script>
import SelectItem from './SelectItem'

export default {
	components: {
		SelectItem,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
		itemKey: {
			type: String,
			default: 'text',
		},
		itemValue: {
			type: String,
			default: 'value',
		},
		itemIcon: {
			type: String,
			default: 'icon',
		},
		value: {
			type: Object,
			default: () => {
				return {
					text: 'HabitApp helps you identify a new habit to build on top of an existing habit.',
					icon: 'habits-icon.png',
					value: 'Phone1.png',
				}
			}
		}
	},
	computed: {
		selected: {
			get() {
				return this.value
			},
			set (newValue) {
				this.$emit('input', newValue)
			}
		}
	}
}
</script>
